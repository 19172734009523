<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.event')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col :cols="form.todo==true ? 6 : 10">
                        <b-row>
                            <b-col cols="4"></b-col>
                            <b-col cols="4">
                                <b-form inline>
                                    <div>{{ $t('common.event.label.todo') }}:</div>
                                    <zw-switch-group v-model="form.todo"
                                                     :label-prefix="'common.event.label.'"
                                                     disable-label
                                                     name="todo"
                                                     class="ml-2"
                                                     :falseValue="false"
                                                     :trueValue="true"
                                                     @input="todoEnabled"
                                    />
                                </b-form>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <zw-select-group v-model="form.action"
                                                 :options="getTodoActions()"
                                                 validate="required"
                                                 name="todo.action"
                                                 size="s"
                                ></zw-select-group>
                            </b-col>
                            <b-col cols="4">
                                <zw-select-group v-model="form.sub_action_id"
                                                 :options="getTodoSubActions()[form.action]"
                                                 name="todo.sub_action"
                                                 size="s"
                                ></zw-select-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <label>{{ $t(labelPrefix + 'description') }}</label>
                                <zw-ckeditor
                                    v-model="form.description"
                                    name="description"
                                />
                            </b-col>
                            <b-col cols="12">
                                <zw-date-group v-model="form.date"
                                               name="date"
                                               :label-prefix="labelPrefix"
                                               :maxDate="currentDate()"
                                ></zw-date-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col :cols="form.todo==true ? 6 : 2">
                        <b-row>
                            <b-col cols="4">&nbsp;</b-col>
                        </b-row>
                        <template v-if="form.todo==true">
                            <b-row>
                                <b-col cols="4">
                                    <zw-select-group v-model="form.todo_user_id"
                                                     :options="users"
                                                     name="todo.user"
                                                     text-field="fullname"
                                                     value-field="id"
                                                     size="s"
                                                     validate="required"
                                    ></zw-select-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="4">
                                    <zw-select-group
                                        v-model="form.todo_action"
                                        :options="getTodoActions()"
                                        :label-prefix="labelPrefix"
                                        name="action"
                                        size="s"
                                        validate="required"
                                    ></zw-select-group>
                                </b-col>
                                <b-col cols="4">
                                    <zw-select-group
                                        v-model="form.todo_sub_action"
                                        :options="getTodoSubActions()[form.todo_action]"
                                        name="todo.sub_action"
                                        size="s"
                                    ></zw-select-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    <zw-date-select-group v-model="form.todoDate"
                                                          name="todo.deadline"
                                                          size="s"
                                    />
                                </b-col>
                                <b-col cols="2" class="pt-4">
                                    <zw-new-time-group v-model="form.todoTime"
                                                       name="deadline_time"
                                                       disable-label
                                                       size="s"
                                    ></zw-new-time-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <zw-input-group v-model="form.todo_title"
                                                    name="todo_title"
                                                    :label-prefix="labelPrefix"
                                                    validate="required"
                                                    size="s"
                                    />
                                </b-col>
                                <b-col cols="12">
                                    <zw-textarea-group v-model="form.todo_description" name="todo.description"/>
                                </b-col>
                            </b-row>
                        </template>
                    </b-col>
                </b-row>

                <div class="form-actions">
                    <b-row>
                        <b-col cols="12">
                            <b-button class="mt-2" block @click="save" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import commonSave from "@/bundles/erika_common_bundle/mixins/common-save";
import todos from "@/bundles/erika_common_bundle/mixins/todos";
import ZwDateSelectGroup from "@/components/ZwDateSelectGroup.vue";

export default {
    name: 'EventModal',
    components: {ZwDateSelectGroup},
    mixins: [commonSave, todos],
    data() {
        return {
            payload: {},
            users: [],
            form: {
                id: null,
                event_id: null,
                type: 'customer',
                todo: 0,
                todoTime: '12:00',
                todoDate: moment().add('1', 'day').format('YYYY-MM-DD'),
            },
            loading: false,
            labelPrefix: 'common.event.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getTodoActions', 'getTodoSubActions', 'getEventsDateOptions', 'getMe']),
        ...mapGetters('Users', ['getUsersList']),
        shown() {
            this.loading = false
            this.$store.dispatch('Users/fetchUsersList').then(() => {
                this.users = this.getUsersList()
            })
            this.form.todo_custom_date = moment().format('YYYY-MM-DD')
            this.form.id = this.payload.id
            if (this.payload.event) {
                this.form.event_id = this.payload.event.id
                this.form.action = this.payload.event.event
                this.form.sub_action_id = this.payload.event.data.sub_action_id
                this.form.description = this.payload.event.data.description
                this.form.date = this.payload.event.created_at
            }

        },
        selectDateOption(option) {
            this.form.todoDate = option
        },
        save() {
            let [hours, minutes] = this.form.todoTime.split(':').map(Number);

            const todoDateTime = moment(this.form.todoDate).set({
                hour: hours,
                minute: minutes,
                second: 0
            });

            this.form.todoDate = todoDateTime

            axios.post(window.apiUrl + '/events/create', this.form, {'skip_loading': true})
                .then(result => {
                    this.commonAfterSave(result, this.sidebar)
                }, () => {
                    this.loading = false
                })
        },
        currentDate() {
            const today = moment();
            return today.format('YYYY-MM-DD');
        },
        todoEnabled(val) {
            if (val) {
                this.form.todo_action = this.form.action
                let me = this.getMe();
                this.form.todo_user_id = me.id
            }
        }
    },
}
</script>