<template>
    <ValidationProvider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group style="margin-bottom: 0px">
            <template v-if="!disableLabel" v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else>
                    {{ $t(labelPrefix + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>
            </template>

            <input type="hidden" v-model="inputVal">

            <zw-date-group v-model="inputVal"
                           name="date"
                           disable-label
                           size="s"
            >
                <template #prepend-slot>
                    <b-input-group-append>
                        <b-button variant="info" @click="selectDateOption('today')">Today</b-button>
                        <b-dropdown>
                            <template #button-content>

                            </template>
                            <b-dropdown-item v-for="(option,key) in getDateOptions()"
                                             :key="key"
                                             @click="selectDateOption(key)"
                            >{{ option }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-input-group-append>
                </template>
            </zw-date-group>

            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </ValidationProvider>
</template>

<script>
import validateMixin from '../mixins/validate'
import moment from 'moment'
import {mapGetters} from "vuex";

export default {
    props: {
        'value': [String, Number],
        'name': {
            type: String,
            required: true
        },
        'validate': [String, Object],
        'icon': [String, Array],
        'label': String,
        'labelPrefix': {
            type: String,
            default: 'common.label.'
        },
        'disableLabel': {
            type: Boolean,
            default: false
        },
        'disableIcon': {
            type: Boolean,
            default: false
        },
        'readonly': {
            type: Boolean,
            default: false
        },
        'size': {
            type: String,
            default: 'lg'
        },
    },
    data() {
        return {
            selectedDateType: 'tomorrow'
        }
    },
    mixins: [validateMixin],
    computed: {
        inputVal: {
            get() {
                return moment(this.value).format('YYYY-MM-DD');
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
    mounted() {
        if (this.inputVal) {
            this.selectedDateType = 'custom'
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getEventsDateOptions']),
        getDateOptions() {
            let values = {};
            this.getEventsDateOptions().forEach((key) => {
                values[key] = this.getOptionText(key)
            })

            return values
        },
        selectDateOption(option) {
            this.$set(this, 'selectedDateType', option)
            this.$set(this, 'inputVal', this.getOptionDate(option).format('YYYY-MM-DD'))
        },
        getOptionDate(key) {
            let date = moment()
            if (key == 'today') {
                date = moment()
            }
            if (key == 'tomorrow') {
                date = moment().add('1', 'day')
            }
            const daysMatch = key.match(/(\d+)_day(?:s)?/);
            if (daysMatch) {
                const days = parseInt(daysMatch[1], 10);
                date = this.addBusinessDays(moment(), days);
            }
            const weeksMatch = key.match(/(\d+)_week(?:s)?/);
            if (weeksMatch) {
                const weeks = parseInt(weeksMatch[1], 10);
                date = moment().add(weeks, 'week')
            }
            const monthsMatch = key.match(/(\d+)_month(?:s)?/);
            if (monthsMatch) {
                const months = parseInt(monthsMatch[1], 10);
                date = moment().add(months, 'month')
            }
            if (key == 'custom') {
                date = moment(this.inputVal, 'YYYY-MM-DD')
            }
            if (key != 'custom' && key != 'today') {
                if (date.weekday() == 6) {
                    date.add('2', 'day')
                }
                if (date.weekday() == 0) {
                    date.add('1', 'day')
                }
            }
            return date
        },
        getOptionText(key) {
            let date = this.getOptionDate(key)
            date = date.format('DD MMM')
            if (key == 'custom') {
                return this.$t('common.event.date_options.' + key)
            } else {
                return this.$t('common.event.date_options.' + key) + ' (' + date + ')'
            }
        },
        addBusinessDays(date, days) {
            let result = moment(date);
            let addedDays = 0;

            while (addedDays < days) {
                result.add(1, 'days');
                if (result.isoWeekday() < 6) {
                    addedDays++;
                }
            }

            return result;
        },
    },
    watch: {}
}
</script>